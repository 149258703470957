import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

const BIRTH_DATE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => BirthDateValidator),
  multi: true
};

@Directive({
  selector: '[appUserBirthDate][formControl],[appUserBirthDate][formControlName]',
  providers: [ BIRTH_DATE_VALIDATOR ],
})
export class BirthDateValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return dirthDateValidator(control);
  }
}

export function dirthDateValidator(control: AbstractControl): ValidationErrors | null {
  let invalid: boolean = true;
  if (control.value) {
    let controlValue: string = new Date(control.value).toUTCString();
    let now: string = new Date().toUTCString();
    if (Date.parse(controlValue) < Date.parse(now)) {
      invalid = !invalid;
      return null;
    }
  }
  return {'invalid_dirth_date': invalid};
}
