import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ITraveller } from 'src/models/traveller.model';

const IDENTIFICATION_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IdentificationValidator),
  multi: true
};

@Directive({
  selector: '[appUserVerification][formControl],[appUserVerification][formControlName]',
  providers: [ IDENTIFICATION_VALIDATOR ],
})
export class IdentificationValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return identificationValidator(control);
  }
}

export function identificationValidator(control: AbstractControl): ValidationErrors | null {
  let invalid: boolean = true;
  const nui: string = String(control.value);
  if (nui && nui.length === 10) {
    let thirdDigit: number = parseInt(nui.substring(2, 3));
    if (thirdDigit < 6) {
      let coefValNui: number[] = [2, 1, 2, 1, 2, 1, 2, 1, 2];
      let checker: number = parseInt(nui.substring(9, 10));
      let [sum, digit] = [0, 0];
      for (let i: number = 0; i < (nui.length - 1); i++) {
        digit = parseInt(nui.substring( i, i + 1 )) * coefValNui[i];
        sum += ((parseInt(( digit % 10 ) + '') + ( parseInt((digit / 10) + ''))));
      }
      sum = Math.round(sum);
      if ((Math.round(sum % 10) === 0) && (Math.round(sum % 10) === checker)) {
        invalid = !invalid;
      } else if ((10 - (Math.round(sum % 10))) == checker) {
        invalid = !invalid;
        return null;
      }
    }
  }
  return invalid ? { 'invalid_nui': invalid } : null;
}
