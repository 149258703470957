import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { emailValidator } from 'src/directives/Validators/email.validator.directive';
import { AuthenticationService } from 'src/services/authentication.service';
import { ModalActionsService } from "../../../services/message/modal-actions.service";
import { MessageService } from "primeng/api";
import { extractErrorResponse } from "../../../utils/extractErrorResponse";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Registration } from "../../../models/authentication/registration.model";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit{
  showDialogPage = false;
  hasRegistrationErrors = false;
  registrationForm: FormGroup;
  viewFormCode = false;
  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private modalActionService: ModalActionsService,
              private messageService: MessageService,
              private translate: TranslateService,
              private ngxService: NgxUiLoaderService,) {
    this.createForm();
  }

  ngRegistrationSubmit(registration: Registration): void {
    if (this.registrationForm.valid) {
      this.ngxService.start();
      this.authenticationService.registration(registration).subscribe({
        next: (response) => {
          this.viewFormCode=true;
          this.hasRegistrationErrors= false;
          this.ngxService.stop();
          this.modalActionService.sendOpenCloseModalActivate(registration.email??'',true);
          this.messageService.add({key: 'messages',severity:'success', summary:this.translate.instant('singles.message_server'), data: [ response.body?.msg ?? ''] });
        },
        error: (errorResponse) => {
          this.messageService.add({key: 'messages',severity:'error', summary:this.translate.instant('singles.message_server'), data: extractErrorResponse(errorResponse,false)});
          this.hasRegistrationErrors= true;
          this.ngxService.stop();
        }
      });
    }
  }

  hasCustomErrors(formField: string, customValidator: string): boolean {
    return this.getControlFormField(formField).value && this.getControlFormField(formField).errors?.[customValidator];
  }

  getControlFormField(field: string): AbstractControl {
    return this.registrationForm.controls[field];
  }

  hasErrors(formField: string): boolean {
    if (this.getControlFormField(formField).touched) {
      return this.getControlFormField(formField).errors != null;
    } else {
      return false;
    }
  }


  ngOnInit(): void {
    this.subscriptions();
  }

  private subscriptions() {
    this.modalActionService.sendOpenCloseModalRegisterObservable.subscribe((response) => {
      this.registrationForm.reset();
      this.showDialogPage= response;
    });
  }

  private createForm() {
    this.registrationForm = this.formBuilder.group({
      first_name: [ null, [ Validators.required, Validators.minLength(4), Validators.maxLength(20) ]],
      last_name: [ null, [ Validators.required, Validators.minLength(4), Validators.maxLength(20) ]],
      email: [ null, [ Validators.required, emailValidator ]],
      password: [ null, [ Validators.required, Validators.minLength(5)]],
    });
  }

  public viewLogin(): void {
    this.modalActionService.sendOpenCloseModalRegister(false);
    this.modalActionService.sendOpenCloseModalActivate('',false);
    this.modalActionService.sendOpenCloseModalLogin(true);
  }
}
