export const environment = {
  production: false,
  urlServer: 'https://qa.api.boomerang.teamq.biz/',
  apiClient: 'APP-TQ-ADMIN',
  apiKey: '$2y$10$w4kCbgBZqQ3IMfLjDVynXecIMs0dKJohvWCcoLus8GkOdm.DglYWC',
  client_app_code_paymentez:  'NUVEISTG-EC-CLIENT',
  client_app_key_paymentez: 'rvpKAv2tc49x6YL38fvtv5jJxRRiPs',
  env_mode_paymentez: 'stg'
};


