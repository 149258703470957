import { Component } from '@angular/core';
import { ModalActionsService } from "../../services/message/modal-actions.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(private modalActionService: ModalActionsService) {
  }

  openCloseRegisterPage(action: boolean): void {
    this.modalActionService.sendOpenCloseModalRegister(action);
  }

  openCloseLoginPage(action: boolean): void {
    this.modalActionService.sendOpenCloseModalLogin(action);
  }
}
