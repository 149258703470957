import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

const DEPARTURE_DATE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DepartureDateValidator),
  multi: true
};

@Directive({
  selector: '[appDepartureDateValidator][formControl],[appDepartureDateValidator][formControlName]',
  providers: [ DEPARTURE_DATE_VALIDATOR ],
})
export class DepartureDateValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return departureDateValidator(control);
  }
}

export function departureDateValidator(control: AbstractControl): ValidationErrors | null {
  let invalid: boolean = true;
  if (control.value) {
    let controlValue: string = new Date(control.value).toUTCString();
    let now: string = new Date().toUTCString();
    if (Date.parse(controlValue) > Date.parse(now)) {
      invalid = !invalid;
      return null;
    }
  }
  return {'invalid_departure_date': invalid};
}
